import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import * as RadixSelect from "@radix-ui/react-select";
import { startTransition, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { useMyCampaigns } from "@/services/campaign";
import { useRouter } from "next/router";
import { Flex } from "@radix-ui/themes";
import useUser from "@/lib/useUser";
import CampaignListSelect from "./CampaignListSelect";

export default function CampaignSearch() {
  const router = useRouter();
  const { user } = useUser();
  const onSelect = (campaign: Campaign) => {
    router.push(
      user?.campaignView === "basic"
        ? `/dashboard/${campaign.id}`
        : `/campaign/${campaign.id}`
    );
  };
  return <CampaignListSelect onSelect={onSelect} />;
}
