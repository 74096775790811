import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import * as RadixSelect from "@radix-ui/react-select";
import { startTransition, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { useMyCampaigns } from "@/services/campaign";
import { useRouter } from "next/router";
import { Flex } from "@radix-ui/themes";
import useUser from "@/lib/useUser";

export default function CampaignListSelect({ onSelect }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const campaigns = useMyCampaigns().data;
  const router = useRouter();
  const { user } = useUser();

  // Get list of campaign names for matching
  let campaignNames = [];
  if (campaigns) {
    for (var i = 0; i < campaigns.length; i++) {
      campaignNames.push({
        label: campaigns[i].name,
        value: campaigns[i].name.toLowerCase(),
      });
    }
  }

  const matches = useMemo(() => {
    if (!searchValue)
      return campaignNames.filter((match) => match.value !== "");
    const keys = ["label", "value"];
    const matches = matchSorter(campaignNames, searchValue, { keys });
    console.log("Og matches", matches);
    return matches.filter((match) => match.value !== "");
  }, [searchValue, value]);
  return (
    <RadixSelect.Root
      value={value}
      onValueChange={(e) => {
        if (campaigns) {
          const selectedCampaignName = campaignNames.find(
            (name) => name.value === e
          );
          const selectedCampaign = campaigns.find(
            (name) => name.name === selectedCampaignName?.label
          );
          if (selectedCampaign) {
            onSelect(selectedCampaign);
            setValue(selectedCampaign.name);
          }
        }
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={(value) => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        <RadixSelect.Trigger
          aria-label="Campaign"
          className="select"
          style={{ height: "48px" }}
        >
          <RadixSelect.Value
            placeholder={
              <Flex direction="row" gap="1" align="center">
                <MagnifyingGlassIcon />
                Search campaign…
              </Flex>
            }
          />
          <RadixSelect.Icon className="select-icon">
            <ChevronDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content
          role="dialog"
          aria-label="Campaigns"
          position="popper"
          className="popover"
          sideOffset={4}
        >
          <div className="combobox-wrapper">
            <div className="combobox-icon">
              <MagnifyingGlassIcon />
            </div>
            <Combobox
              autoSelect
              placeholder="Enter campaign name"
              className="combobox"
              // Ariakit's Combobox manually triggers a blur event on virtually
              // blurred items, making them work as if they had actual DOM
              // focus. These blur events might happen after the corresponding
              // focus events in the capture phase, leading Radix Select to
              // close the popover. This happens because Radix Select relies on
              // the order of these captured events to discern if the focus was
              // outside the element. Since we don't have access to the
              // onInteractOutside prop in the Radix SelectContent component to
              // stop this behavior, we can turn off Ariakit's behavior here.
              onBlurCapture={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </div>
          <ComboboxList className="listbox" key={`${JSON.stringify(matches)}`}>
            {matches.map(({ label, value }, index) => (
              <RadixSelect.Item
                key={`${value}-${index}`}
                value={value}
                asChild
                className="item"
                style={{ cursor: "pointer" }}
              >
                <ComboboxItem style={{ cursor: "pointer" }}>
                  <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                  <RadixSelect.ItemIndicator className="item-indicator">
                    <CheckIcon />
                  </RadixSelect.ItemIndicator>
                </ComboboxItem>
              </RadixSelect.Item>
            ))}
          </ComboboxList>
        </RadixSelect.Content>
      </ComboboxProvider>
    </RadixSelect.Root>
  );
}
