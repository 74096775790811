import {
  addBudget,
  deleteBudget,
  updateBudget,
  updateCampaign,
  updateTracking,
  useCampaignBudgets,
} from "@/services/campaign";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Separator,
  Switch,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useUsers } from "@/services/user";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useArtists, useSongs } from "@/services/artist";
import {
  IconCurrencyDollar,
  IconMusic,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import NewMultiSelect from "@/components/CustomUiComponents/NewMultiSelect";
import Search from "@/components/CustomUiComponents/NewSearch";
import ClearableSelect from "@/components/CustomUiComponents/ClearableSelect";
import { useCreatorTags } from "@/services/creator";

interface SettingsProps {
  campaign: Campaign;
  mutate: any;
  transferable: any;
  mobile?: boolean;
}
const SettingsTab = ({
  campaign,
  mutate,
  transferable,
  mobile,
}: SettingsProps) => {
  const { data: tags } = useCreatorTags(); // now called formats in the UI
  const { data: budgets, mutate: mutateBudgets } = useCampaignBudgets(
    campaign.id as any,
  );
  const [visibleBudgets, setVisibleBudgets] = useState([]);

  const toggleTracking = async () => {
    await updateTracking(campaign);
    mutate();
  };
  const [name, setName] = useState(campaign.name);
  const BUDGET_DEFAULT = {
    platform: "",
    creatorTypeId: "",
    budget: 0,
  };
  const [newBudget, setNewBudget] = useState<any>(BUDGET_DEFAULT);
  const [instagramBudget, setInstagramBudget] = useState(
    campaign.instagramBudget.toString(),
  );
  const [tiktokBudget, setTiktokBudget] = useState(
    campaign.tiktokBudget.toString(),
  );
  const [trackAllCreators, setTrackAllCreators] = useState(
    campaign.trackAllCreators,
  );
  const [streams, setStreams] = useState(campaign.streamTarget.toString());
  const [dailyStreams, setDailyStreams] = useState(
    campaign.dailyStreams.toString(),
  );
  const { data: artists } = useArtists();
  const [updating, setUpdating] = useState(false);
  const [invisibleBookedPosts, setInvisibleBookedPosts] = useState(
    campaign.invisibleBookedPosts,
  );
  const [percentOverrideEnabled, setPercentOverrideEnabled] = useState(
    campaign.percentOverride != undefined && campaign.percentOverride !== null,
  );

  const [percentOverride, setPercentOverride] = useState<
    number | undefined | null
  >(campaign.percentOverride);

  const [artist, setArtist] = useState(
    campaign.artist
      ? { label: campaign.artist.name, value: campaign.artist.id }
      : null,
  );
  const { data: songs } = useSongs(campaign?.artist?.id);

  const [song, setSong] = useState(
    campaign.song
      ? { label: campaign.song.name, value: campaign.song.id }
      : null,
  );

  const artistOptions = artists
    ? artists.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    : [];

  const songOptions = songs?.Song
    ? songs.Song.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    : [];

  const { data: users } = useUsers();
  const newBudgetRef = useRef(null);
  const [admins, setAdmins] = useState<string[]>([]);
  const removeAdmin = (email) => {
    const newAdmins = admins.filter((adminEmail) => adminEmail !== email);
    setAdmins(newAdmins);
  };
  useEffect(() => {
    setAdmins(campaign.admins.map((admin) => admin.user?.email));
  }, [campaign]);
  const update = async () => {
    setUpdating(true);
    const adminIds = admins.map(
      (admin) => users.find((u) => admin === u.email).id,
    );
    await updateCampaign(campaign.id, {
      name,
      admins: adminIds,
      instagramBudget: parseInt(instagramBudget || "0"),
      tiktokBudget: parseInt(tiktokBudget || "0"),
      streamTarget: parseInt(streams || "0"),
      dailyStreams: parseInt(dailyStreams || "0"),
      artist: artist?.value || null,
      song: song?.value || null,
      trackAllCreators: trackAllCreators,
      percentOverride: percentOverrideEnabled ? percentOverride : undefined,
      invisibleBookedPosts,
    });
    const updatedBudgets = visibleBudgets.filter((b) => b.updated);
    for (const budget of updatedBudgets) {
      await updateBudget(
        budget.id,
        budget.creatorTypeId,
        parseInt(budget.budget),
        budget.platform,
      );
    }
    setUpdating(false);
    mutate();
    setTimeout(() => mutateBudgets(), 1000);
  };

  useEffect(() => {
    setVisibleBudgets(budgets);
  }, [budgets]);

  const budgetComponents = useMemo(() => {
    return visibleBudgets?.map((budget, i) => {
      return (
        <Flex key={budget.id} direction="row" gap="3">
          <Flex width="15%">
            <ClearableSelect
              value={budget.platform}
              placeholder="Platform..."
              options={[
                { label: "Instagram", value: "instagram" },
                { label: "Tiktok", value: "tiktok" },
                { label: "Twitter", value: "twitter" },
                { label: "Youtube", value: "youtube" },
              ]}
              onValueChange={(value) => {
                const newB = visibleBudgets[i];
                newB.platform = value;
                newB.updated = true;
                setVisibleBudgets([...visibleBudgets]);
              }}
            />
          </Flex>
          <Flex width="15%">
            <ClearableSelect
              value={budget.creatorTypeId}
              placeholder="Post Format..."
              options={tags?.map((tag) => ({ label: tag.tag, value: tag.id }))}
              onValueChange={(value) => {
                const newB = visibleBudgets[i];
                newB.creatorTypeId = value;
                newB.updated = true;
                setVisibleBudgets([...visibleBudgets]);
              }}
            />
          </Flex>
          <Box>
            <TextField.Root
              defaultValue={budget.budget}
              onChange={(e) => {
                const newB = visibleBudgets[i];
                let val = e.target.value;
                if (val === "" || !isNaN(val as any)) {
                  newB.budget = e.target.value;
                  newB.updated = true;
                }
              }}
              placeholder="Enter budget"
              radius="medium"
              style={{ width: "200px", height: "36px" }}
              variant="soft"
            >
              <TextField.Slot pr="4">
                <IconCurrencyDollar className="icon-dark" width="20px" />
              </TextField.Slot>
            </TextField.Root>
          </Box>
          <Button
            onClick={() => {
              deleteBudget(budget.id);
              setTimeout(() => mutateBudgets(), 1000);
            }}
            variant="solid"
          >
            <IconTrash className="icon-accent" />
          </Button>
        </Flex>
      );
    });
  }, [visibleBudgets]);

  return (
    <Flex
      direction="column"
      gap="5"
      py="6"
      pl={mobile ? "5" : "0"}
      minWidth={mobile ? "200vw" : "none"}
    >
      <Heading size="4">Campaign Settings</Heading>
      <Flex direction="row" gap="3" pt="4" width="100%">
        <Flex width="15%">
          <Text size="2">Tracking</Text>
        </Flex>
        <Flex gap="2">
          <Switch
            onCheckedChange={() => {
              toggleTracking();
            }}
            checked={campaign.shouldTrack}
          />
          <Text>{campaign.shouldTrack ? "On" : "Off"}</Text>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3" pt="4" width="100%">
        <Flex width="15%">
          <Text size="2">Track Bookings Only</Text>
        </Flex>
        <Flex gap="2">
          <Switch
            onCheckedChange={() => {
              setTrackAllCreators(!trackAllCreators);
            }}
            checked={!trackAllCreators}
          />
          <Text>{!trackAllCreators ? "On" : "Off"}</Text>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3" width="100%">
        <Flex width="15%">
          <Text size="2">Name</Text>
        </Flex>
        <Box>
          <TextField.Root
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Name"
            radius="medium"
            style={{ width: "300px", height: "36px" }}
            variant="soft"
          />
        </Box>
      </Flex>
      <Flex direction="row" gap="3" width="100%">
        <Flex width="15%">
          <Text size="2">Admins</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <NewMultiSelect
            title="Search Users"
            options={users?.map((i) => i.email) || []}
            selected={admins}
            setSelected={setAdmins}
            style={{ height: "36px" }}
          />
          <Flex gap="1">
            {admins.length ? (
              admins?.map((i) => (
                <Badge radius="large" key={i}>
                  {i}
                  <IconButton variant="ghost" onClick={() => removeAdmin(i)}>
                    <IconX className="icon-accent" height="10px" />
                  </IconButton>
                </Badge>
              ))
            ) : (
              <Text size="1">No admins yet.</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3" width="100%">
        <Flex width="15%">
          <Text size="2">Artist</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <Search
            placeholder="Search artists..."
            data={artistOptions || []}
            onChange={setArtist}
            prompt="Enter artist name"
            def={artist || null}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text size="2">Song</Text>
        </Flex>
        <Flex direction="column" gap="2" width="300px">
          <Search
            placeholder="Search songs..."
            data={songOptions || []}
            onChange={setSong}
            prompt="Enter song name"
            def={song || null}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text size="2">Percentage Override</Text>
        </Flex>
        <Flex direction="row" gap="2" width="300px">
          <Flex direction="row" gap="2" width="30%">
            <Flex>
              <Switch
                onCheckedChange={(s) => setPercentOverrideEnabled(s)}
                checked={percentOverrideEnabled}
              />
            </Flex>
            <Flex>
              <Text>{percentOverrideEnabled ? "On" : "Off"}</Text>
            </Flex>
          </Flex>
          <Flex width="70%">
            {percentOverrideEnabled && (
              <TextField.Root
                value={percentOverride}
                onChange={(e) => {
                  setPercentOverride(+e.target.value);
                }}
                placeholder="Percentage Override"
                radius="medium"
                style={{ width: "100%", height: "36px" }}
                variant="soft"
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text size="2">Hide Booked Posts</Text>
        </Flex>
        <Flex direction="row" gap="2" width="300px">
          <Switch
            onCheckedChange={() => {
              setInvisibleBookedPosts(!invisibleBookedPosts);
            }}
            checked={invisibleBookedPosts}
          />
          <Text>{invisibleBookedPosts ? "On" : "Off"}</Text>
        </Flex>
      </Flex>

      <Separator my="3" size="4" />

      <Heading size="4">Budget</Heading>
      {budgetComponents.length === 0 ? (
        <Text size="2">No budgets yet.</Text>
      ) : (
        budgetComponents
      )}
      <Separator my="1" size="3" />
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <ClearableSelect
            value={newBudget.platform}
            placeholder="Platform..."
            options={[
              { label: "Instagram", value: "instagram" },
              { label: "Tiktok", value: "tiktok" },
              { label: "Twitter", value: "twitter" },
              { label: "Youtube", value: "youtube" },
            ]}
            onValueChange={(value) =>
              setNewBudget((prev) => ({ ...prev, platform: value }))
            }
          />
        </Flex>
        <Flex width="15%">
          <ClearableSelect
            value={newBudget.creatorTypeId}
            placeholder="Post Format..."
            options={tags?.map((tag) => ({ label: tag.tag, value: tag.id }))}
            onValueChange={(value) =>
              setNewBudget((prev) => ({ ...prev, creatorTypeId: value }))
            }
          />
        </Flex>
        <Box>
          <TextField.Root
            defaultValue={0}
            ref={newBudgetRef}
            placeholder="Enter budget"
            radius="medium"
            style={{ width: "200px", height: "36px" }}
            variant="soft"
          >
            <TextField.Slot pr="4">
              <IconCurrencyDollar className="icon-dark" width="20px" />
            </TextField.Slot>
          </TextField.Root>
        </Box>
        <Button
          onClick={() => {
            const budgetValue = newBudgetRef.current?.value;
            if (
              !newBudget.platform ||
              !newBudget.creatorTypeId ||
              !budgetValue
            ) {
              alert("Please fill out the required fields");
            } else {
              addBudget(
                campaign.id as any,
                newBudget.creatorTypeId,
                parseInt(budgetValue),
                newBudget.platform,
              );
              setTimeout(() => mutateBudgets(), 1000);
              setNewBudget(BUDGET_DEFAULT);
              if (newBudgetRef.current) {
                newBudgetRef.current.value = 0;
              }
            }
          }}
          variant="solid"
        >
          Add
        </Button>
      </Flex>

      <Separator my="3" size="4" />

      <Heading size="4">Spotify Goals</Heading>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text size="2">Stream Goal</Text>
        </Flex>
        <Box>
          <TextField.Root
            value={streams}
            onChange={(e) => {
              let val = e.target.value;
              if (val === "" || !isNaN(val as any)) {
                setStreams(e.target.value);
              }
            }}
            placeholder="Enter desired number..."
            radius="medium"
            style={{ width: "300px", height: "36px" }}
            variant="soft"
          >
            <TextField.Slot pr="4">
              <IconMusic className="icon-dark" width="20px" />
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      <Flex direction="row" gap="3">
        <Flex width="15%">
          <Text size="2">Daily Streams</Text>
        </Flex>
        <Box>
          <TextField.Root
            value={dailyStreams}
            onChange={(e) => {
              let val = e.target.value;
              if (val === "" || !isNaN(val as any)) {
                setDailyStreams(e.target.value);
              }
            }}
            placeholder="Enter desired number..."
            radius="medium"
            style={{ width: "300px", height: "36px" }}
            variant="soft"
          >
            <TextField.Slot pr="4">
              <IconMusic className="icon-dark" width="20px" />
            </TextField.Slot>
          </TextField.Root>
        </Box>
      </Flex>
      <Flex direction="row" width="30%">
        <Button onClick={update} loading={updating} variant="solid">
          Update
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(SettingsTab);
