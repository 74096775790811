"use client";
import { useState } from "react";
import {
  Button,
  Callout,
  Flex,
  Text,
  TextField,
  IconButton,
} from "@radix-ui/themes";
import { IconExclamationCircle, IconArrowMerge } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomUiComponents/Dialog";
import { mergeCampaigns, useCampaign } from "@/services/campaign";
import { useRouter } from "next/router";
import CampaignListSelect from "../NewCampaignViews/CampaignListSelect";

export default function MergeCampaign({ campaignId }: { campaignId: number }) {
  const { data: campaign, mutate } = useCampaign(campaignId.toString());
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const router = useRouter();
  const [toMerge, setToMerge] = useState<Campaign | null>(null);

  const mergeCampaign = async (e) => {
    setError("");
    if (campaign.name !== confirmText) {
      setError("Campaign name does not match");
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (!toMerge.id) {
      setError("Please select a campaign to merge into");
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    try {
      const resp = await mergeCampaigns(campaignId, toMerge.id);
      if (resp.error) {
        console.log(resp.error);
        e.preventDefault();
        e.stopPropagation();
        setError(resp.error);
      } else {
        setConfirmText("");
        router.push("/campaign");
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <IconButton variant="ghost">
          <IconArrowMerge className="icon" />
        </IconButton>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content>
          <Dialog.Title>Merge Campaign</Dialog.Title>

          <Flex direction="column" gap="3">
            <Text size="2">
              This action will delete the campaign, transfers all associated
              data to the new campaign, and <b>cannot be undone</b>.
            </Text>
            {error && (
              <Callout.Root color="red">
                <Callout.Icon>
                  <IconExclamationCircle />
                </Callout.Icon>
                <Callout.Text>{error}</Callout.Text>
              </Callout.Root>
            )}
            <label>
              <Text as="div" size="2" mb="1">
                Enter the campaign name to confirm
              </Text>
              <Text as="div" size="2" mb="1" weight="bold">
                {campaign?.name}
              </Text>
              <TextField.Root
                placeholder="Enter campaign name"
                onChange={(e) => {
                  setConfirmText(e.target.value);
                }}
                value={confirmText}
              />
            </label>
            <Flex width="100%">
              <CampaignListSelect onSelect={setToMerge} />
            </Flex>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button
                variant="soft"
                color="gray"
                onClick={() => {
                  setConfirmText("");
                  setError("");
                }}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button onClick={mergeCampaign}>Merge</Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
