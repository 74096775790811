import { capitalize } from "@/lib/util";
import fetchJson from "@/lib/fetchJson";
import { Button, Flex, IconButton, Table, Text } from "@radix-ui/themes";
import { IconTrash, IconX } from "@tabler/icons-react";
import Image from "next/image";
import * as Dialog from "@/components/CustomUiComponents/Dialog"

interface PostDraftsProps {
  drafts: PostDraft[];
  mutate: any;
  editable: boolean;
  id: number;
}

const DraftsTab = ({ drafts, mutate, editable, id }: PostDraftsProps) => {
  const remove = async (id: number) => {
    if (confirm("Are you sure you want to delete this draft?")) {
      await fetchJson(`/postDraft/${id}`, {
        method: "DELETE",
      });
      mutate();
    }
  };
  const headers = [
    "Post",
    "Author",
    "Status",
]
  if (!drafts?.length)
    return (
      <>No post drafts yet.</>
    )

  return (
    <div>
        <Table.Root>
            <Table.Header>
                <Table.Row>
                    {headers.map((item: any) => (
                        <Table.ColumnHeaderCell key={item}>{item}</Table.ColumnHeaderCell>
                    ))}
                    {editable && <Table.ColumnHeaderCell key={"Actions"}>{"Actions"}</Table.ColumnHeaderCell>}
                </Table.Row>
            </Table.Header>

            <Table.Body>
            {drafts.map((draft) => (
                <Table.Row  key={draft.id} >
                    <Table.Cell maxWidth="10px">
                        <Dialog.Root>
                        <Dialog.Trigger>
                        {draft.thumbnail ? <div className="w-20 cursor-pointer"> <Image fill 
                                
                                src={`/image-upload/${draft.thumbnail}`}
                                alt="thumbnail"
                                /> </div>:
                                <Button variant="outline">View Draft</Button>}
                        </Dialog.Trigger>
                      <Dialog.Portal>
                        <Dialog.Content>
                            <Flex direction="column" gap="2">
                                <Dialog.Close>
                                    <Flex direction="row" justify="end">
                                        <IconButton variant="ghost">
                                            <IconX/>
                                        </IconButton>
                                    </Flex>
                                </Dialog.Close>
                                    <video
                                    src={`${process.env.NEXT_PUBLIC_DOMAIN}/image-upload/${draft.videoPath}`}
                                    controls
                                    className="w-full"
                                    />
                            </Flex>
                        </Dialog.Content>
                        </Dialog.Portal>
                        </Dialog.Root>
                    </Table.Cell>
                    <Table.Cell>
                        {(draft as any).creatorPrice.username && 
                        <Text>@{(draft as any).creatorPrice.username}</Text>}
                    </Table.Cell>
                    <Table.Cell >{draft.status && capitalize(draft.status)}</Table.Cell>
                    {editable && <Table.Cell >
                        <IconButton
                        onClick={() => remove(draft.id)}
                        variant="outline"
                        >
                            <IconTrash className="icon-accent"/>
                        </IconButton>
                    </Table.Cell>}
                </Table.Row>
          ))}
            </Table.Body>
        </Table.Root>
    </div>
  );
};

export default DraftsTab;
